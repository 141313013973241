<template>
  <v-autocomplete
    v-model="passport"
    item-text="label"
    item-value="id"
    class="pt-2"
    outlined
    clearable
    dense
    hide-details
    :disabled="disabled"
    :items="passportList"
    :label="$t('appeal.passport_id')"
    :loading="isLoading"
    return-object
  ></v-autocomplete>
</template>

<script>
export default {
  name: 'BasePassportSelect',
  props: {
    value: Number,
    passportList: Array,
    disabled: Boolean
  },
  data () {
    return {
      isLoading: false
    }
  },
  computed: {
    passport: {
      get () {
        return this.passportList.find(passport => passport.value === this.value)
      },
      set (val) {
        this.$emit('input', val.value)
      }
    }
  }
}
</script>

<style scoped>

</style>
