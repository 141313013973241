<template>
  <v-card
    v-if="passportList"
    outlined
    class="elevation-1 rounded-0"
  >
    <v-card-title class="d-flex justify-space-between">
      {{ detail.id ? $t('appeal.title') + ' #' + detail.id : $t('appeal.title') }}
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <BasePassportSelect
            v-model="detail.passport_id"
            :passport-list="passportList"
            class="mb-5"
          />
          <BaseDatePicker
            v-model="detail.appeal_date"
            :label="$t('appeal.appeal_date')"
          />
          <v-text-field
            v-model="detail.full_name"
            color="secondary"
            :label="$t('appeal.full_name')"
            dense
            outlined
            class="mb-0"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-alert
            class="mt-3"
            type="info"
            v-if="detail.created_at"
          >Срок подготовки ответа 14 дней. До {{ dateToExpire }} включительно
          </v-alert>
          <v-alert
            :type="typeAlertByStatus()"
            v-if="detail.status"
          >Статус: {{ statusById(detail.status) }}
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card
            outlined
            tile
          >
            <v-card-title>{{ $t('appeal.request_attachment') }}</v-card-title>
            <BaseDocuments
              :value-docs="appealAttachmentFormatter(detail.requestAttachment)"
              :model-id="detail.id"
              accept-types="application/pdf, .docx, .doc, .xls, .xlsx, .jpg, .jpeg, .png"
              model-name="appealRequest"
              :multiple="false"
              :title="'Прикрепить новое обращение'"
              @input="handleInputRequest"
            />
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card
            outlined
            tile
          >
            <v-card-title>{{ $t('appeal.response_attachment') }}</v-card-title>
            <BaseDocuments
              :value-docs="appealAttachmentFormatter(detail.responseAttachment)"
              :model-id="detail.id"
              accept-types="application/pdf, .docx, .doc, .xls, .xlsx, .jpg, .jpeg, .png"
              model-name="appealResponse"
              :multiple="false"
              :title="'Прикрепить ответ'"
              :disabled="!canResponse || detail.status === 2"
              id-upload="files"
              @input="handleInputResponse"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import serviceDataComputeds from '@/components/mixins/serviceDataComputeds'
import formatters from '@/components/mixins/formatters'
import BaseDatePicker from '@/components/base/BaseDatePicker'
import BaseDocuments from '@/components/base/BaseDocuments'
import { cleanClone } from '@/components/utils/common'
import BasePassportSelect from '@/components/base/forms/BasePassportSelect'
import _ from 'lodash'

const DaysToExpire = 14

export default {
  mixins: [serviceDataComputeds, formatters],
  name: 'DetailView',
  components: { BaseDocuments, BaseDatePicker, BasePassportSelect },
  props: {
    value: Object
  },
  data () {
    return {
      detail: {}
    }
  },
  computed: {
    dateToExpire () {
      if (this.detail.created_at) {
        let date = new Date(this.detail.created_at * 1000)
        date.setDate(date.getDate() + DaysToExpire)
        return date.toLocaleDateString('ru-RU')
      }
    },
    passportList () {
      return this.$store.getters['serviceData/get']('passport.list')
    },
    canResponse () {
      return !!this.detail.requestAttachment
    }
  },
  methods: {
    appealAttachmentFormatter (item) {
      if (item === null) {
        item = []
      } else if (_.isObjectLike(item)) {
        item = [{
          id: this.detail.id,
          file: { ...item }
        }]
      }
      return item
    },
    handleInputResponse (value) {
      let localValue = cleanClone(this.value)
      localValue.responseAttachment = value
      this.$emit('input', localValue)
    },
    handleInputRequest (value) {
      let localValue = cleanClone(this.value)
      localValue.requestAttachment = value
      this.$emit('input', localValue)
    },
    typeAlertByStatus () {
      let status = 'info'
      switch (this.detail.status) {
        case 2:
          status = 'success'
          break
        case 3:
          status = 'error'
          break
      }
      return status
    }
  },
  watch: {
    value: {
      handler () {
        this.detail = this.value
      },
      deep: true,
      immediate: true
    },
    'detail.responseAttachment': {
      handler () {
        if (this.detail.responseAttachment && this.detail.responseAttachment.length) {
          this.detail.status = 2
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
