export default {
  methods: {
    typeByLabel (label) {
      const typeList = this.$store.getters['serviceData/get']('handbookCategory.types')
      let type = null
      if (typeList) {
        type = typeList.find(item => item.label === label).type
      }
      return type
    },
    roleByName (name) {
      const roleList = this.$store.getters['serviceData/get']('organization.roles')
      let role = null
      if (roleList) {
        role = roleList.find(item => item.name === name).label
      }
      return role
    },
    roleListByName (roleList) {
      return roleList.reduce((result, item) => result += this.roleByName(item) + "\n", '')
    },
    statusById (id) {
      const statusList = this.$store.getters['serviceData/get']('appeal.statuses')
      let status = null
      if (statusList) {
        status = statusList.find(item => item.status === id).label
      }
      return status
    }
  }
}
